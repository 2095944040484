/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  @apply border border-blue-900 rounded-lg px-2 py-1 m-1 text-xs;
}

button:hover {
  @apply bg-blue-900 text-white;
}

.is-active {
  @apply bg-blue-900 text-white;
}

li {
  @apply list-disc;
}

.blurred {
  filter: blur(4px);
  pointer-events: none; /* Prevents interaction */
  user-select: none; /* Prevents text selection */
}
