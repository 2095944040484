@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.merriweather-light {
  font-family: "Merriweather", system-ui;
  font-weight: 300;
  font-style: normal;
}

.merriweather-regular {
  font-family: "Merriweather", system-ui;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", system-ui;
  font-weight: 700;
  font-style: normal;
}

.merriweather-black {
  font-family: "Merriweather", system-ui;
  font-weight: 900;
  font-style: normal;
}

.merriweather-light-italic {
  font-family: "Merriweather", system-ui;
  font-weight: 300;
  font-style: italic;
}

.merriweather-regular-italic {
  font-family: "Merriweather", system-ui;
  font-weight: 400;
  font-style: italic;
}

.merriweather-bold-italic {
  font-family: "Merriweather", system-ui;
  font-weight: 700;
  font-style: italic;
}

.merriweather-black-italic {
  font-family: "Merriweather", system-ui;
  font-weight: 900;
  font-style: italic;
}

.bubble {
  @apply p-2 rounded-xl bg-white text-gray-600;
}

.header {
  @apply text-blue-900 font-bold text-sm uppercase text-left;
}

.blue-button {
  @apply bg-blue-900 text-white text-sm px-2 py-1 rounded-lg;
}

.close-button {
  @apply underline text-gray-600 text-xs absolute top-2 right-2;
}

.label {
  @apply uppercase font-bold text-xs text-gray-600;
}
